import axios from 'axios';
import {ref} from "vue";
import { useApiConfig } from '~/composables/useApiConfig';
//const apiBaseURL = process.env.API_URL;



export const setCSRFToken = async () => {
    const { apiBaseURL } = useApiConfig();
    try {
        const response = await axios.get(`${apiBaseURL}/sanctum/csrf-cookie`);
        // CSRF token is set in cookies, and Laravel adds it to the request headers
    } catch (error) {
        console.error('Error setting CSRF token:', error);
        throw error;
    }
};

export const uploadCalendar = async (calendar_id, name, description, level, activation_date, imageFile, days, tags) => {
    const toast = useToast()
    await setCSRFToken(); // Ensure CSRF token is set before making the request

    const formData = new FormData();
    formData.append('id', calendar_id);
    formData.append('name', name);
    formData.append('description', description);
    formData.append('level', level);
    formData.append('activation_date', activation_date);
    formData.append('image_file', imageFile);
    formData.append('days', JSON.stringify(days.value));
    formData.append('tags', JSON.stringify(tags.value));

    try {
        const {data, error} = await useApiFetch("/api/calendar/upload", {
            method: "POST",
            body: formData,
        });
        if (error.value){
            toast.add({ title: 'Error uploading calendar! ', color: "red" })
        } else{
            toast.add({ title: 'Calendar uploaded!' })
        }
        return data.data;
    } catch (error) {

         console.error('Error calendar music:', error);
        throw error;
    }
};


export const getAllCalendar = async () => {
    await setCSRFToken(); // Ensure CSRF token is set before making the request
    const { apiBaseURL } = useApiConfig();

    try {
        const response = await axios.get(`${apiBaseURL}/api/calendar/all`);
        return response.data.calendar;
    } catch (error) {
        console.error('Error fetching calendar:', error);
        throw error;
    }
};

export const getCalendar = async (id) => {
    await setCSRFToken(); // Ensure CSRF token is set before making the request
    const { apiBaseURL } = useApiConfig();

    try {
        const response = await axios.get(`${apiBaseURL}/api/calendar/show/${id}`);
        return response;
    } catch (error) {
        console.error('Error fetching calendar:', error);
        throw error;
    }
};

export const getTiers = async (id) => {

    await setCSRFToken(); // Ensure CSRF token is set before making the request
    const { apiBaseURL } = useApiConfig();

    try {
        const response = await axios.get(`${apiBaseURL}/api/getTiers`);
        return response;
    } catch (error) {
        console.error('Error fetching calendar:', error);
        throw error;
    }
};

export const fetchSearchCalendars = async (value) => {
    await setCSRFToken(); // Ensure CSRF token is set before making the request
    const { apiBaseURL } = useApiConfig();

    try {
        const response = await axios.get(`${apiBaseURL}/api/calendar/search`, {
            params: { search: value }
        });
        return response;
    } catch (error) {
        console.error('Error fetching calendar:', error);
        throw error;
    }
};

export const fetchSpecialCalendars = async () => {
    await setCSRFToken(); // Ensure CSRF token is set before making the request
    const { apiBaseURL } = useApiConfig();


    try {
        const response = await axios.get(`${apiBaseURL}/api/calendar/special-calendars`);
        return response.data;  // Assuming the response format matches the expected structure
    } catch (err) {

        console.error("Failed to fetch special calendars:", err);
        return err;
    }
};

export const deleteCalendar = async (id) => {
    await setCSRFToken(); // Ensure CSRF token is set before making the request

    try {
        const {data, error} = await useApiFetch(`/api/calendar/destroy/${id}`, {
            method: "DELETE",
        });
        return data;

    } catch (error) {
        console.error('Error deleting calendar:', error);
        throw error;
    }
};

export const createPaymentIntent = async () => {
    let clientSecret = '';
    await setCSRFToken(); // Ensure CSRF token is set before making the request
    try {
        const response = await useApiFetch(`/api/create-payment-intent`, {
            method: 'POST',
            body: JSON.stringify({amount: 1000}), // Example amount in cents
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching client secret:', error);
    }
};
